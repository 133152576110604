.header-view {
  .header {
    padding: 16px 16px 16px 18px;
    width: 100vw;
  }
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

@media only screen and (max-width: 1280px) {
  .header-view {
    .header {
    }
  }
}

@media only screen and (max-width: 680px) {
  .header-view {
    .header {
    }
  }
}
